@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "GraphikBold";
	src: local("GraphikBold"), url(./assets/fonts/GraphikBold.otf) format("truetype");
}
@font-face {
	font-family: "GraphikMedium";
	src: local("GraphikMedium"), url(./assets/fonts/GraphikMedium.otf) format("truetype");
}
@font-face {
	font-family: "GraphikSemibold";
	src: local("GraphikSemibold"), url(./assets/fonts/GraphikSemibold.otf) format("truetype");
}
@font-face {
	font-family: "GraphikRegular";
	src: local("GraphikRegular"), url(./assets/fonts/GraphikRegular.otf) format("truetype");
}

.helping-text {
	display: none;
	transition: 5000ms all ease-in;
}

.helping-button:hover + .helping-text {
	display: flex;
	transition: 5000ms all ease-in;
}
