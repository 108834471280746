#screen {
    background-image: url("/public/Background.png");
    background-color: #104172;
    /* Used if the image is unavailable */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
}

@media (min-width: 1024px) {
    .main-title {
        line-height: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        color: #ffffff;
    }

    .label {
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #ffffff;
    }

    .footer-home {
        margin-bottom: 27px;

        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #ffffff;
    }

    #ellipse-top {
        width: 313px;
        height: 313px;
        right: -69px;
        top: -14px;

        /* azul-claro */

        background: #31b5d8;
        filter: blur(457px);
    }

    #ellipse-bottom {
        width: 415px;
        height: 415px;
        left: -114px;
        bottom: -63px;
        filter: blur(457px);
    }

    .title-mobile {
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        color: #ffffff;
    }

    .guide-item {
        width: 90%;

        background: linear-gradient(180deg, #0f4172 0%, #0e4577 8.65%, #03324f 100%);
        box-shadow: 12px 14px 56px rgba(255, 255, 255, 0.25);
        border-radius: 15px;
    }

    .label-title {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
    }

    .text-intruction {
        width: 251px;
        height: 24px !important;
        left: 147px;
        top: 137px;

        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
    }

    .label-title-menu {
        height: 40px;
    }

    .title-carousell {
        font-family: "Graphik";
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* identical to box height, or 100% */

        text-align: center;

        color: #ffffff;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .item-carousell-hint {
        font-family: "Graphik";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* or 100% */

        color: #ffffff;
    }

    .home-button {
        margin-bottom: 14px !important;
    }

    .instructions-text {
        font-family: "Graphik";
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: white;
    }

    .color-after:hover::after {
        content: "";
        position: absolute;
        top: 95px;
        left: 14px;
        width: 54px;
        height: 54px;
        border-radius: 100%;
        background: #13a6e0;
        opacity: 0.7;
    }

    .avatar-icon {
        box-shadow: 5px 0px 8px 1px #46a3c2;
        -webkit-box-shadow: 5px 0px 8px 1px #46a3c2;
        -moz-box-shadow: 5px 0px 8px 1px #46a3c2;
    }

    .header-dialogue {
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
    }

    .text-dialogue {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #3b3c3e;
    }

    .notification-title {
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
    }

    .notification-body {
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        color: #3b3c3e;
    }
}

.prueba {
    background-color: #ffffff4b;
}