body {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
	background: #0f4172;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
canvas {
	cursor: grab;
}
canvas:active {
	cursor: grabbing;
}
.strong-text {
	font-weight: bold;
	font-size: 18px;
	color: #0577be;
}
.strong-task {
	font-weight: bold;
	font-size: 18px;
}
#stats {
	height: 50px;
}

.public-main {
	background: #0f4172;
}
.public-main-back {
	background: linear-gradient(180deg, #0f4172 0%, #0e4577 8.65%, #03324f 100%);
	box-shadow: 12px 14px 56px rgba(255, 255, 255, 0.25);
	border-radius: 15px;
}
/* .main-title {
	font-family: "GraphikBold";
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 32px;

	text-align: center;

	color: #ffffff;
} */
.guide-title {
	font-style: normal;
	font-weight: 700;
	line-height: 40px;

	color: #ffffff;
}
.guide-subtitle {
	font-style: normal;
	font-weight: 500;
	line-height: 24px;

	color: #ffffff;
}
.guide-body {
	font-family: "Graphik";
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	/* or 100% */

	color: #ffffff;
}
.guide-instructions {
	font-family: "Graphik";
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	/* or 125% */
	color: white;
}
.label-title {
	/* Mobile-h3 */

	font-family: "Graphik";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	/* or 100% */

	text-align: center;

	/* Blanco */

	color: #ffffff;
}
.label-register {
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: #ffffff;
}

#ellipse-top {
	position: fixed;
	border-radius: 50%;

	/* Primarios/azul-claro */
	z-index: 10000000;
	background: #31b5d8;
	filter: blur(228.5px);
}
#ellipse-bottom {
	position: fixed;
	border-radius: 50%;
	z-index: 10000000;
	/* azul-claro */

	background: #31b5d8;
	filter: blur(228.5px);
}
.error-label {
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	border-radius: 50%;
	color: #ffffff;
}

#loading {
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

.label-character {
	/* Mobile-h4 */

	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	color: #ffffff;
}

.avatar-active {
	border: 3px #f28e14 solid;
}

.text-intruction {
	font-family: "Graphik";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	/* identical to box height, or 100% */

	color: #ffffff;
	width: 211px;
	height: 20px !important;
}

.guide-item {
	background: linear-gradient(180deg, #0f4172 0%, #0e4577 8.65%, #0a3d67 45.84%, #03324f 100%);
	box-shadow: 12px 14px 44px rgba(255, 255, 255, 0.1);
	border-radius: 16px;
}

.title-carousell {
	font-family: "Graphik";
	font-style: normal;
	font-weight: 600;
	/* or 71% */

	text-align: center;

	color: #ffffff;
}
.item-carousell-text {
	width: 100%;
	font-style: normal;
	font-weight: 500;
	line-height: 10px;
	text-align: center;
	color: #ffffff;
}

.item-carousell-text-task {
	font-family: "Graphik";
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	margin-top: 8px;
	/* or 125% */

	/* Blanco */

	color: #ffffff;
}
.carousel-slider {
	height: 100%;
}
.slider-wrapper {
	height: 100%;
}
.slider-wrapper ul {
	height: 100%;
}

.label-sprite {
	text-shadow: -1px 1px 1px rgb(0, 0, 0);
	font-size: 20px;
	width: 100px;
	height: 100vh;
}

.avatar-text {
	font-style: normal;
	font-weight: 700;
	line-height: 32px;
	color: #ffffff;
}

.icon-text {
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.mail-header {
	font-style: normal;
	font-weight: 900;
	text-align: left;
}

.mail-title {
	font-style: normal;
	font-weight: 500;
	line-height: 38px;
	text-align: left;
	color: #3b3c3e;
}

.direct-mail-title {
	font-style: normal;
	font-weight: 700;
	color: #ffffff;
}

.direct-mail-person {
	font-style: normal;
	font-weight: 600;
	text-align: left;
}

.direct-mail-body {
	font-style: normal;
	font-weight: 400;
	text-align: justify;
}

.item-table-client {
	font-style: normal;
	font-weight: 600;
	line-height: 38px;
	padding: 8px 0px;
}
.item-table-own-client {
	font-style: normal;
	font-weight: 400;
	line-height: 38px;
}

.th-client {
	font-style: normal;
	font-weight: 900;
	line-height: 40px;
}
.hover-effect {
	transition: all 200ms;
}
.hover-effect:hover {
	transform: scale(1.02);
}
.icon-hover-effect {
	transition: transform 100ms ease-in-out;
}
.icon-hover-effect:hover {
	transform: scale(1.05);
}

.shaking {
	/* Start the shake animation and make the animation last for 0.5 seconds */
	animation: shake 0.5s;

	/* When the animation is finished, start again */
	animation-iteration-count: 1s;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes shake {
	0% {
		transform: translate(0.5px, 0.5px) rotate(0deg);
	}
	10% {
		transform: translate(-0.5px, -1px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(1.5px, 1px) rotate(0deg);
	}
	40% {
		transform: translate(0.5px, -0.5px) rotate(1deg);
	}
	50% {
		transform: translate(-0.5px, 1px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 0.5px) rotate(0deg);
	}
	70% {
		transform: translate(1.5px, 0.5px) rotate(-1deg);
	}
	80% {
		transform: translate(-0.5px, -0.5px) rotate(1deg);
	}
	90% {
		transform: translate(0.5px, 1px) rotate(0deg);
	}
	100% {
		transform: translate(0.5px, -1px) rotate(-1deg);
	}
}

@media (max-width: 900px) and (orientation: landscape) {
	.public-main {
		padding-top: 24px;
	}
	.content-center {
		margin-top: 40px;
	}
	.main-register {
		padding-top: 16px;
	}
	.button-content {
		margin-bottom: 20px;
	}
	.home-button {
		margin-bottom: 14px !important;
	}
}
