* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.shadow-login {
	-webkit-box-shadow: 5px 5px 27px 9px rgba(0, 0, 0, 0.32);
	box-shadow: 5px 5px 27px 9px rgba(0, 0, 0, 0.32);
}
